import request from '@/utils/request';

export async function checkShare(data) {
  return request('/agent/weixin/checkShare', {
    method: 'POST',
    data,
  });
}

export async function saveShare(data) {
  return request('/agent/weixin/saveShare', {
    method: 'POST',
    data,
  });
}
