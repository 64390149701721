import { getAuth, getNewUsers } from './service';


const Model = {
  namespace: 'operator',
  state: {
    authList: [],
    list: {

    },
    userList: {
      page: -1,
      size: 10,
      totalPage: 0,
      totalItem: 0,
      data: [],
      hasMore: true,
    },
  },
  effects: {
    *getAuthList({}, { put, call }) {
      const res = yield call(getAuth, {});
      if (res.status === 1) {
        yield put({
          type: 'saveAuth',
          payload: res.data,
        })
      } else {
        alert('获取权限失败，请稍后重试')
      }
    },
    *getUserList({ payload }, { put, call, select }) {
      if (payload.isInit) {
        yield put({
          type: 'initUserList',
        })
      }
      const userList = yield select(state => state.operator.userList);
      const res = yield call(getNewUsers, {
        ...payload,
        size: userList.size,
        page: userList.page + 1,
      });
      if (res.status === 1) {
        yield put({
          type: 'saveList',
          payload: res.data,
        })
      }
    },
  },
  reducers: {
    save(state, action) {
      return { ...state,
        list: action.payload,
      };
    },
    saveAuth(state, action) {
      return {
        ...state,
        authList: action.payload,
      }
    },
    initUserList(state) {
      return {
        ...state,
        userList: {
          page: -1,
          size: 10,
          totalPage: 0,
          totalItem: 0,
          data: [],
          hasMore: true,
        },
      };
    },
    saveList(state, { payload }) {
      const oldAllRows = state.userList.data;
      const newData = {
        ...payload,
        data: [...oldAllRows, ...payload.data],
      }

      const hasMore = payload.data.length >= state.userList.size;
      newData.hasMore = hasMore;
      return {
        ...state,
        userList: newData,
      };
    },
  },
};
export default Model;
