import request from '@/utils/request';

export async function getUserDetail(params) {
  return request('/agent/user/detail', {
    params,
  });
}

export async function getDataDetail(params) {
  return request('/agent/operator/getDataDetail', {
    params,
  });
}

export async function getRate(params) {
  return request('/agent/user/rate', {
    params,
  })
}
