import React, { Component } from 'react';
import { connect } from 'dva';
import { Helmet } from 'react-helmet';
import './index.less';



@connect(({ loading,  user }) => ({
  currentUser: user.currentUser,
}))
class Home extends Component {
  componentDidMount() {
  }
  render() {

    return (
      <>
        <Helmet>
            <title>悦惠欢迎您</title>
        </Helmet>
        <div className="header">
          
        </div>
      </>
    );
  }
}

export default Home;
