import request from '@/utils/request';

export async function getSmsCode(data) {
  return request('/agent/sms/reg', {
    method: 'POST',
    data,
  });
}

export async function changeUserName(data) {
  return request('/agent/user/changeUserName', {
    method: 'POST',
    data,
  });
}