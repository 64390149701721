import React from 'react';
import './style.less';

class LoadMore extends React.PureComponent {
  // 里面有子组件是真实的东西, 第一次渲染真实组件自己渲染, 然后后续，观察滚动条高度， 如果是到底部，则触发加载事件，如果已经到底结束则不再触发滚动观察事件
  constructor (props) {
    super(props);
  }

  componentDidMount() {
    const {
      handleLoading,
    } = this.props;
    const scrollFn = ev => {
      const ch = document.documentElement.clientHeight;
      const scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
      const efTop = this.refs.loadMoreRef.offsetTop;
      if (ch + scrollTop + 200 >= efTop) {
        !this.props.loading && handleLoading && handleLoading();
      }
    }
    this.scrollFn = scrollFn;
    window.addEventListener('scroll', this.scrollFn, false);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollFn);
  }

  render () {
    const {
      loading,
      hasMore,
      children,
     } = this.props;
    return (
      <>
        {children}
        <div className="loadMoreRef" ref="loadMoreRef">
        {
          loading ? <div className="loadMore">加载中...</div> :
          hasMore ? '' : <div className="loadMore">没有更多数据了</div>
        }
        </div>
      </>
    );
  }
}

export default LoadMore;
