import { getUserDetail, getDataDetail, getRate } from './service';


const Model = {
  namespace: 'operatorDetail',
  state: {
    rate: 0.52,
    detail: {

    },
    dataDetail: {

    },
  },
  effects: {
    *rate({}, { put, call }) {
      const res = yield call(getRate);
      if (res.status === 1) {
        yield put({
          type: 'saveRate',
          payload: res.data.rate,
        })
      }
    },
    *dataDetail({ payload }, { put, call, select }) {
      if (payload.isInit) {
        yield put({
          type: 'initDetailData',
        })
      }
      const res = yield call(getDataDetail, payload);
      if (res.status === 1) {
        yield put({
          type: 'saveDataDetail',
          payload: res.data,
        })
      }
    },
  },
  reducers: {
    saveRate(state, action) {
      return { ...state,
        rate: action.payload,
      };
    },
    initDetailData(state) {
      return {
        ...state,
        dataDetail: {},
      };
    },
    saveDataDetail(state, { payload }) {
      return {
        ...state,
        dataDetail: payload,
      };
    },
  },
};
export default Model;
