import React from 'react';

class DataNone extends React.PureComponent {
    constructor (props) {
        super(props);
        this.state = {};
    }

    render () {
        const { dataNoneTxt } = this.props;
        return (
            <section className="mod-dataNone">
                <img src="/static/images/common/noData.png" alt=""/>
                <p>{dataNoneTxt}</p>
            </section>
        )
    }
}

export default DataNone;
