import React, { Component } from 'react';
import { connect } from 'dva';
import { Helmet } from 'react-helmet';

import Tabs from '@/components/Tabs';

const TabPane = Tabs.TabPane;
const DAY_TAB = 'tab';
const MONTH_TAB = 'month';


import './index.less';

function getPhone (tel) {
  return tel.replace(/^(\d{4})\d{4}(\d+)/, '$1****$2');
}

function getPrice (price = 0) {
  price = +price;
  return `￥${(price / 100).toFixed(2)}`;
}

const roles = {
  COMMON: '消费',
  AGENT: '代理',
  OPERATOR: '运营',
}

@connect(({ loading, operatorDetail }) => ({
  operatorDetail,
  loading: loading.effects[ 'operatorDetail/dataDetail' ],
}))
class OperatorDetail extends Component {
  state = {
    showDay: true,
  }

  componentDidMount () {
    this.props.dispatch({
      type: 'operatorDetail/rate',
    })
    this.props.dispatch({
      type: 'operatorDetail/dataDetail',
      payload: {
        agentId: this.props.location.query.id,
        page: 0,
        size: 10,
      },
    });
  }

  renderTable = (list = [], tab) => {
    return list.map((item, inx) => {
      return (
        <div key={inx} className={'item flex items-center justify-center line'}>
            { 
              (tab === DAY_TAB)
              ? (<div>
                  {item.month>9?item.month:('0'+item.month)}-{item.day>9?item.day:('0'+item.day)}
                </div>)
              : (<div>
                {item.month>9?item.month:('0'+item.month)}月
              </div>)
            }
            <div>
                <p>结算收入</p>
                <p>￥{(item.balanceAmount / 100).toFixed(2)}</p>
            </div>
            <div>
                <p>订单数</p>
                <p>{item.orderCountNum}</p>
            </div>
            <div>
                <p>效果预估</p>
                <p>￥{(item.effectAmount / 100).toFixed(2)}</p>
            </div>
        </div>
      )
    })
  }

   /**
     * 分离不同年份的每月集合
     * @param  {Array}  originList 每月的原始集合
     * @return {Array<Array>}      不同年份的每月集合
     */
    separateMonth(originList = []) {
      let years = originList.map(i => i.year);
      years = new Set(years);
      years = [...years];
      const separateMonth = years.map(i => {
        const curMonths = originList.filter(j => j.year === i);
        return curMonths;
      })
      return separateMonth || [];
    }

  render () {
    const {
      operatorDetail,
    } = this.props;

    const { dataDetail: { agentUserCommVO } } = operatorDetail;
    const init = !!agentUserCommVO;
    const separateMonths = this.separateMonth(operatorDetail.dataDetail.monthClickStatVOs); // 每月的
    return (
      <div>
        {
          init ? <div className="operator-detail">
            <div className="profile-wrap">
              <div className="Consumer flex items-center justify-center no-line">
                <div className="img">
                  <img src={agentUserCommVO.avatar} alt="头像" />
                </div>
                <div className="info">
                  <div className="phone">
                    <span>{getPhone(agentUserCommVO.userName)}</span>
                    <span className="role">{roles[ agentUserCommVO.role ]}</span>
                  </div>
                  <div className="nick-name">{agentUserCommVO.nickName}</div>
                  <div className="info-desc">注册时间：{new Date(agentUserCommVO.createdAt).toLocaleDateString().replace(/\//g, '-')}</div>
                </div>
                <div className="count">
                  <p>团队人数</p>
                  <p>{operatorDetail.dataDetail.teamNum}</p>
                </div>
              </div>
              <div className="superior-info">
                <div>他的上级用户</div>
                <div className="info">
                  <img src={operatorDetail.dataDetail.parentUseravatar} alt="上级用户头像" />
                  <span className="tel">{operatorDetail.dataDetail.parentUserNickName}</span>
                  <span>&nbsp;|&nbsp;</span>
                  <span>{getPhone(operatorDetail.dataDetail.parentUserName)}</span>
                </div>
              </div>
            </div>
            <div className="mar-top line data flex items-center justify-center">
              <div>
                <p>{(operatorDetail.dataDetail.monthBalanceAmount / 100).toFixed(2)}</p>
                <p>本月结算收入(元)</p>
              </div>
              <div>
                <p>{(operatorDetail.dataDetail.lastMonthBalanceAmount / 100).toFixed(2)}</p>
                <p>上月结算收入(元)</p>
              </div>
            </div>
            <div className="data flex items-center justify-center">
                <div>
                    <p>{(operatorDetail.dataDetail.monthEffectAmount / 100).toFixed(2)}</p>
                    <p>本月预估收入(元)</p>
                </div>
                <div>
                    <p>{(operatorDetail.dataDetail.lastMonthEffectAmount / 100).toFixed(2)}</p>
                    <p>上月预估收入(元)</p>
                </div>
            </div>
            <Tabs defaultActiveKey={DAY_TAB}>
              <TabPane tabKey={DAY_TAB} tab="每日">
                <div className="his">
                  <div className="title">
                    <div className="point"></div>
                    <span>{operatorDetail.dataDetail.clickStatVOs.length > 0 ? (operatorDetail.dataDetail.clickStatVOs[0].year + '年' + operatorDetail.dataDetail.clickStatVOs[0].month + '月') : ''}</span>
                  </div>
                  { this.renderTable(operatorDetail.dataDetail.clickStatVOs, DAY_TAB) }
                </div>
              </TabPane>
              <TabPane tabKey={MONTH_TAB} tab="每月">
                {
                  separateMonths.map((i, index) => (
                    <div className="his" key={index}>
                      <div className="title">
                        <div className="point"></div>
                        <span>{i.length > 0 ? `${i[0].year} 年` : '暂无数据'}</span>
                      </div>
                      { this.renderTable(i, MONTH_TAB) }
                    </div>
                  ))
                }
              </TabPane>
            </Tabs>
          </div> : ''
        }
      </div>
    )
  }
}

export default OperatorDetail;
