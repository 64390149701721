import React, { Component } from 'react';
import { connect } from 'dva';
import { Helmet } from 'react-helmet';



@connect(({ loading, user, unbind }) => ({
  currentUser: user.currentUser,
}))
class Unbind extends Component {
  state = {
  }
  componentDidMount () {
    const { location: {query: { pd }} } = this.props;
    this.props.dispatch({
      type: 'downloadModel/check',
      payload: pd,
    })
  }
  render () {
    return (
      <section className="unbind-wrap">
        <Helmet>
          <title>悦惠下载</title>
        </Helmet>
      </section>
    );
  }
}

export default Unbind;
