import { getSmsCode, changeUserName } from './service';
import { sleep } from '@/utils/utils';


const Model = {
  namespace: 'unbind',
  state: {
    countDown: 0,
  },
  effects: {
    *submitForm({ payload, callback}, { put, call }) {
      const res = yield call(changeUserName, payload);
      if (res.status === 1) {
        alert('操作成功, 您可以用新手机号码登录悦惠');
        location.reload();
      } else {
        alert(res.msg);
      }
      if (callback) callback();
    },
    *getCode({ payload, callback}, { put, call }) {
      const res = yield call(getSmsCode, payload);
      if (res.status === 1) {
        yield put({
          type: 'startCountDown',
          payload: 5,
        })
      } else {
        alert('系统异常，请稍后重试');
      }
      if (callback) callback();
    },
    *startCountDown({payload}, { put, call, select }) {
      if (payload >= 0) {
        yield put({
          type: 'saceCountDown',
          payload,
        });
      }

      if (payload >= 0) {
        yield sleep(1000);
        yield put({
          type: 'startCountDown',
          payload: payload - 1,
        })
      }
    }
  },
  reducers: {
    saceCountDown(state, action) {
      return { ...state,
        countDown: action.payload,
      };
    },
  },
};
export default Model;
