import React from 'react';
import './index.less';

export default class NewSearch extends React.PureComponent {
    inter;

    constructor(prop) {
        super(prop);
        this.handleChange = this.handleChange.bind(this);
        this.focusChange = this.focusChange.bind(this);
        this.clear = this.clear.bind(this);
        this.state = {
            focus: false,
            text: this.props.searchText || ''
    }
    }

    clear() {
        this.setState({
            text: ''
        });
        this.props.handleChange('');
        this.handleSubmit();
    }

    focusChange(type) {
        this.setState({
            focus: type === 1,
        });
    }

    handleChange(value) {
        this.setState({
            text: value
        });
        if (this.inter) {
            clearTimeout(this.inter);
        }
        this.inter = setTimeout(() => {
            this.props.handleChange(value);
            this.inter = null;
        }, 100);
    }

    handleSubmit() {
        this.props.handleSubmit();
    }

    render() {
        const {
            placeholder = '请输入关键词',
            editStatus = false,
            searchText = '',
            type = 'search'
        } = this.props;
        const { focus, text, ulShow } = this.state;

        return (
            <div>
                <form className="h-search" onSubmit={() => this.handleSubmit()}>
                    <input value={text} onFocus={() => this.focusChange(1)} onBlur={() => this.focusChange(2)}
                           onChange={(e) => this.handleChange(e.target.value)} type={type} placeholder={placeholder}/>
                    <i className="iconfont">&#xe60b;</i>
                    {text && <div className="clear" onClick={() => this.clear()}>
                        <img src="http://xwstatic.js571.com/oldQn/Clear.png" alt=""/>
                    </div>}
                    <div className="search-btn" onClick={() => this.handleSubmit()}>
                        <span>搜索</span>
                    </div>
                </form>
                {focus && <div className="h-mark">
                </div>}
            </div>
        );
    }
}
