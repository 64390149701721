import React, { Component } from 'react';
import { connect } from 'dva';
import { Helmet } from 'react-helmet';
import Input from '@/components/Input';
import './index.less';



@connect(({ loading, user, unbind }) => ({
  currentUser: user.currentUser,
  countDown: unbind.countDown,
  getCoding: loading.effects['unbind/getCode'],
}))
class Unbind extends Component {
  state = {
    editMode: false,
    code: '',
    tel: '',
  }
  componentDidMount () {
  }
  changeMode = () => {
    this.setState({
      editMode: true,
    })
  };
  updateForm = (field, value) => {
    this.setState({
      [ field ]: value,
    });
  }
  submitForm = () => {
    const {
      code,
      tel
    } = this.state;
    const params = {
      userName: tel,
      inviteCode: this.props.currentUser.inviteCode,
      code: (code || '').replace(/\s/gi, ''),
      domain: 49,
    }
    this.props.dispatch({
      type: 'unbind/submitForm',
      payload: params
    });
  }
  getReg = () => {
    const { tel } = this.state;
    const validForm = tel !== '';
    if (validForm) {
      this.props.dispatch({
        type: 'unbind/getCode',
        payload: { domain: 49, userName: tel },
      });
    }
  }
  render () {
    const {
      editMode,
      tel,
      code,
    } = this.state;
    const {
      getCoding,
      countDown,
      currentUser,
    } = this.props;
    const validForm = code !== '' && tel !== '';
    return (
      <section className="unbind-wrap">
        <Helmet>
          <title>修改手机号</title>
        </Helmet>
        {
          !editMode ? <section className="static-form">
            <div className="form-item flex justify-between">
              <span className="static-label">手机号</span>
              <span className="static-no">{currentUser.userName}</span>
            </div>
            <button data-animation="button_ani_2" className={'btn btn-red'} onClick={this.changeMode}>修改手机号</button>
          </section> : <section className="register-form">
            <div className="form-wrap">
              <div className="form-item">
                <Input
                  onChange={(v) => {
                    this.updateForm('tel', v);
                  }}
                  label={'手机号码'}
                  type="tel"
                  name="tel"
                  value={tel}
                />
              </div>
              <div className="line"> </div>
              <div className="form-item flex items-center">
                <div className="flex-1">
                  <Input
                    onChange={(v) => {
                      this.updateForm('code', v);
                    }}
                    label={'验证码'}
                    type="tel"
                    value={code}
                  />
                </div>
                {
                  countDown === 0 &&
                  <button data-animation="button_ani_2" disabled={getCoding} className={'active'} onClick={() => { this.getReg() }}>获取</button>
                }
                {
                  countDown > 0 &&
                  <button>{countDown}s</button>
                }
              </div>
            </div>
            {validForm && <button
              onClick={this.submitForm}
              data-animation="button_ani_2"
              className={'btn btn-red'}
            >
              验证
             </button>}
            {
              !validForm && <button
                className={'btn btn-gray'}
              >
                验证
               </button>
            }
          </section>
        }
      </section>
    );
  }
}

export default Unbind;
