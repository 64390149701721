
const Model = {
  namespace: 'flow',
  state: {
    data: {
      rows: [],
      page: 0,
      size: 10,
      count: 0,
      allRows: [],
    },
  },
  subscriptions: {
    setupHistory({ dispatch, history }) {
      history.listen(location => {
        const { pathname, query } = location;
        if (pathname === '/') {
          
        }
      });
    },
  },
  effects: {
    
  },
  reducers: {
    save(state, { payload }) {
      const oldAllRows = state.data.allRows;
      const newData = {
        ...payload,
        allRows: [ ...oldAllRows, ...payload.rows ],
      }
      return {
        ...state,
        data: newData,
      };
    },
  },
};
export default Model;
