import { checkShare, saveShare } from './service';


const Model = {
  namespace: 'downloadModel',
  state: {
  },
  effects: {
    *check({ payload, callback}, { put, call }) {
      yield call(checkShare, {
        channel: 'sjdlm',
      })
      yield put({
        type: 'save',
        payload,
      });
    },
    *save({ payload, callback}, { put, call }) {
      try {
        yield call(saveShare, {
          channel: 'sjdlm',
          inviteCode: payload,
        })
      } catch (e) {
        console.log(e);
      }
      window.location.href = 'http://android.myapp.com/myapp/detail.htm?apkName=com.ouer.agent.app';
    },
  },
  reducers: {
    
  },
};
export default Model;
