import request from '@/utils/request';

export async function getAuth(data) {
  return request('/agent/operator/getAuth', {
    method: 'POST',
    data,
  });
}

export async function getNewUsers(data) {
  return request('/agent/pc/operator/getNewUsers', {
    method: 'POST',
    data,
  });
}