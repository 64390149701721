import React, { Component } from 'react';
import { connect } from 'dva';
import { Helmet } from 'react-helmet';
import NewSearch from '@/components/NewSearch';
import LoadMore from '@/components/LoadMore';
import DataNone from '@/components/DataNone';


import './index.less';

function getPhone (tel) {
  return tel.replace(/^(\d{4})\d{4}(\d+)/, '$1****$2');
}

function getPrice (price = 0) {
  price = +price;
  return `￥${(price / 100).toFixed(2)}`;
}

const roles = {
  COMMON: '消费',
  AGENT: '代理',
  OPERATOR: '运营',
}

@connect(({ loading, operator }) => ({
  operator,
  loading: loading.effects['operator/getUserList'],
}))
class Operator extends Component {
  state = {
    searchText: '',
    realText: '',
    accountType: null,
    orderType: 1,
    currentSort: 'A',
  }

  componentDidMount () {
    this.props.dispatch({
      type: 'operator/getAuthList',
      payload: null,
    });
    this.queryData();
  }

  changeText = e => {
    this.setState({
      searchText: e,
    })
  }

  changeAccountType = v => {
    this.setState({
      accountType: v,
    }, () => {
      this.queryData(1);
    })
  }

  changeSort = v => {
    const {
      orderType,
      currentSort,
    } = this.state;
    const isOldS = currentSort === v;
    const newSort = getNewSort(v, isOldS, orderType);
    function getNewSort (value, isOld, ot) {
      if (value === 'A') {
        if (isOldS) {
          return ot === 0 ? 1 : 0;
        }
        return 1;
      }
      if (value === 'B') {
        if (isOldS) {
          return ot === 2 ? 3 : 2;
        }
        return 3;
      }
      if (value === 'C') {
        if (isOldS) {
          return ot === 4 ? 5 : 4;
        }
        return 5;
      }
      return 1;
    }
    this.setState({
      orderType: newSort,
      currentSort: v,
    }, () => {
      this.queryData(1);
    });
  }

  searchClick = () => {
    const { searchText } = this.state;
    this.setState({
      realText: searchText,
    }, () => {
      this.queryData(1);
    })
  }

  getSortParams = () => {
    const {
      orderType,
      currentSort,
    } = this.state;
    let keyName = 'createSort';
    if (currentSort === 'B') {
      keyName = 'effectAmountSort';
    }
    if (currentSort === 'C') {
      keyName = 'pullNewNumSort';
    }
    const value = orderType % 2 === 0 ? '0' : '1';
    return {
      [keyName]: value,
    }
  }

  queryData = isInit => {
    const {
      accountType,
      realText,
    } = this.state;
    const params = {
      isInit,
      userRole: accountType,
      ...this.getSortParams(),
    }
    if (realText) {
      params.userName = realText;
    }
    this.props.dispatch({
      type: 'operator/getUserList',
      payload: params,
    })
  }

  render () {
    const {
      realText,
      accountType,
      orderType,
    } = this.state;
    const {
      operator,
      loading,
    } = this.props;
    const sortaActive = [0, 1].indexOf(orderType) > -1;
    const sortbActive = [2, 3].indexOf(orderType) > -1;
    const sortcActive = [4, 5].indexOf(orderType) > -1;

    const loadingMethod = {
      loading,
      hasMore: operator.userList.hasMore,
      handleLoading: this.queryData,
    }


    const isOperator = !!(operator.authList.find(auth => auth.authType === 'UPUSER'));
    return (
      <section className="operator-wrap">
        <Helmet>
          <title>运营商管理系统</title>
        </Helmet>
        {isOperator && <div className="operator">
          <NewSearch
            placeholder="输入手机号或后四位进行搜索"
            editStatus="true"
            type="tel"
            handleChange={e => {this.changeText(e)}}
            searchText={this.state.searchText}
            handleSubmit={this.searchClick}
          />
          <div className="content-box">
            <div className="role-select">
              <div className={accountType === null ? 'active' : ''} onClick={() => this.changeAccountType(null)}>全部</div>
              <div className={accountType === 'COMMON' ? 'active' : ''} onClick={() => this.changeAccountType('COMMON')}>消费者</div>
              <div className={accountType === 'AGENT' ? 'active' : ''} onClick={() => this.changeAccountType('AGENT')}>代理商</div>
            </div>
          </div>
          <ul className="single-order-wrap">
            <li className="order-item-wrap">
              <div className={`order-item ${sortaActive ? 'active' : ''}
              ${orderType === 0 ? 'top-active' : ''}
              ${orderType === 1 ? 'bottom-active' : ''}
              `}
                onClick={() => { this.changeSort('A') }}>
                注册时间
              </div>
            </li>
            <li className="order-item-wrap">
              <div className={`order-item ${sortbActive ? 'active' : ''}
              ${orderType === 2 ? 'top-active' : ''}
              ${orderType === 3 ? 'bottom-active' : ''}
              `}
                onClick={() => { this.changeSort('B') }}>
                昨日下单收入
              </div>
            </li>
            <li className="order-item-wrap">
              <div className={`order-item ${sortcActive ? 'active' : ''}
              ${orderType === 4 ? 'top-active' : ''}
              ${orderType === 5 ? 'bottom-active' : ''}
              `}
                onClick={() => { this.changeSort('C') }}>
                昨日拉新
              </div>
            </li>
          </ul>
          <LoadMore
            {...loadingMethod}
          >
            <div className="list">
              {
                operator.userList.data.map(user => <div className="Consumer consumer-wrap flex items-center justify-between" >
                  <div className="img">
                    <img src={user.avatar} alt="头像" />
                  </div>
                  <div className="info flex-1">
                    <div className="phone">
                      <span>{getPhone(user.userName)}</span>
                      <span className="role">{roles[user.role]}</span>
                    </div>
                    <div className="nick-name">{user.nickName}</div>
                    <div>
                      <div className="info-desc">昨日下单：{getPrice(user.effectAmount)} &nbsp;&nbsp; 昨日拉新：{user.pullNewNum || 0}人</div>
                      <div className="info-desc">提成：{getPrice(user.opAmount)}</div>
                    </div>
                  </div>
                  {
                  (user.role === 'COMMON') 
                  ? (<></>)
                  : (<a className="detail" href={`/operatorDetail?id=${user.id}`}>
                      <span>查看数据</span>
                      <div className="arrow"></div>
                    </a>)
                }
                </div>)
              }
              { !loading && operator.userList.data.length === 0 && <DataNone dataNoneTxt="暂无数据" /> }
            </div>
          </LoadMore>

        </div>}
        {
          !isOperator && !loading && <div className="auth_error">
            <img src="http://xwstatic.js571.com/oldQn/user_error.png" alt="" />
            <p>对不起，您没有权限访问本页面 </p>
          </div>
        }
      </section>
    );
  }
}

export default Operator;
