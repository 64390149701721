import React from 'react';
import { connect } from 'dva';
import PageLoading from '@/components/Loading';
import { domain } from '../../config/custom';


class SecurityLayout extends React.Component {
  state = {
    isReady: false,
  };

  componentDidMount() {
    const { dispatch } = this.props;

    if (dispatch) {
      dispatch({
        type: 'user/fetchCurrent',
        callback: () => {
          this.setState({
            isReady: true,
          });
        },
      });
    }
  }

  render() {
    const { isReady } = this.state;
    const { children, loading, currentUser } = this.props; // You can replace it to your authentication rule (such as check token exists)
    // 你可以把它替换成你自己的登录认证规则（比如判断 token 是否存在）

    const isLogin = currentUser && currentUser.userName;

    if ((!isLogin && loading) || !isReady) {
      return <PageLoading />;
    }

    if (!isLogin) {
      const redirectServerUri = 'http://restapi.'+ domain +'/uc/dispatch';
      const redirectUri = location.href.replace(/&/g, '--') + '&domain=49&qunId=123&oauthPlatform=sjdlm';
      const state = 'http://dl.'+ domain +'|prod'
      const rUrl = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx680d6fa2bc637693&redirect_uri=${encodeURIComponent(redirectServerUri + '?referer=' + redirectUri)}&response_type=code&scope=snsapi_userinfo&state=${encodeURIComponent(state)}#wechat_redirect`;
      location.href = rUrl;
      // return children;
    } else {
      return children;
    }
  }
}

export default connect(({ user, loading }) => ({
  currentUser: user.currentUser,
  loading: loading.models.user,
}))(SecurityLayout);
